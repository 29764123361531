import * as React from "react";
import PropTypes from "prop-types";
import Extentsion from "./Extension/Extension";
import TemplateScreen from "./webApp/TemplateScreen";
import { ThemeProvider } from "./themeContext/ThemeContext";
import AppProviders from "../../context";
import { ClerkProvider } from "@clerk/clerk-react";

// Main App component that integrates various providers and the main Extension component
export default function App() {
  return (
    // AppProviders component to wrap the app with context providers for global state management
    <AppProviders>
      {/* ClerkProvider for authentication, using the provided publishable key */}
      <ClerkProvider publishableKey={`pk_test_ZHluYW1pYy1qYWNrYWwtMTYuY2xlcmsuYWNjb3VudHMuZGV2JA`}>
        {/* ThemeProvider for theme context management */}
        <ThemeProvider>
          {/* Main Extension component */}
          <Extentsion />
        </ThemeProvider>
      </ClerkProvider>
    </AppProviders>
  );
}

// Define the prop types for the App component

App.propTypes = {
  // Optional string title prop
  title: PropTypes.string,
  // Optional boolean to indicate if Office is initialized
  isOfficeInitialized: PropTypes.bool,
};
