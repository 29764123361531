import App from "./components/App";
import { AppContainer } from "react-hot-loader";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import * as React from "react";
import * as ReactDOM from "react-dom";

/* global document, Office, module, require */

const render = (Component) => {
  // Function to handle authentication status change
  initializeIcons();

  ReactDOM.render(
    <AppContainer>
      <App />
    </AppContainer>,
    document.getElementById("container")
  );
};

/* Render application after Office initializes */
// Define the isOfficeInitialized flag
let isOfficeInitialized = false;


// Register the Office.onReady event
Office.onReady(function (info) {
  if (info.host === Office.HostType.Word || info.host === Office.HostType.Excel) {
    // The Office.js library is initialized, and it's safe to use the Office API.
    isOfficeInitialized = true;
    //window.onArrowButtonClick = onArrowButtonClick;
  } else {
    console.log("Office.js is not supported in this host application.");
  }
});

// Later in your code, check the isOfficeInitialized flag before using the Office API
if (isOfficeInitialized) {
  // Use the Office API here
} else {
  console.log("Office.js is not yet initialized.");
}

/* Initial render showing a progress bar */
render(App);

if (module.hot) {
  module.hot.accept("./components/App", () => {
    const NextApp = require("./components/App").default;
    render(NextApp);
  });
}
