import React from 'react';
import {GlobalProvider } from './global';

const AppProviders = ({ children }) => {
    return (
        <GlobalProvider>
            {children}
        </GlobalProvider>
    );
};

export default AppProviders;