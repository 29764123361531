import React, { useState } from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import { useTheme } from "../themeContext/ThemeContext";
import { Grid, Card, CardMedia, CardContent, Typography } from "@mui/material";
import { PrimaryButton, Image, Text } from "office-ui-fabric-react";
import CardModal from "../../modalPopup/CardModal";
import axios from "axios";

function OnlineTemplate({
  handleToggleChange,
  insertDiagramAsImage,
  OneDriveLocalData,
  setOneDriveFiles,
  oneDriveFiles,
  setGithubShareModal,
}) {
  // Styles for the component
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      width: "72%",
    },
    row: {
      display: "flex",
      justifyContent: "space-between",
      gap: "10px",
    },
    button: {
      backgroundColor: "#B6472B",
      borderRadius: "8px",
      height: 35,
      fontSize: 11,
      minWidth: 79,
      border: "none",
      cursor: "pointer",
      flex: 1,
    },
    "@media (max-width: 600px)": {
      container: {
        width: "90%",
      },
      row: {
        flexDirection: "column",
        gap: "10px",
      },
    },
  };
  // Accessing the current theme from a custom hook
  const { currentTheme } = useTheme();
  // State variables
  const [modalCard, setModalCard] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [directMermaidDaigram, setDirectMermaidDaigram] = useState("");
  const [directGraphvizDaigram, setDirectGraphvizDaigram] = useState("");
  const [apiAllData, setApiAllData] = useState([]);

  let backgroundColor;
  // Determine background color based on current theme
  if (currentTheme === "vs-light") {
    backgroundColor = "white";
  } else if (currentTheme === "vs-dark") {
    backgroundColor = "#212529";
  } else if (currentTheme === "hc-black") {
    backgroundColor = "#000";
  }
  // Fetch all diagrams from API
  async function fetchData() {
    try {
      const response = await axios.get("https://mrk.elenta.co/visual/api/all-diagrams/");
      console.log(" graph response ===>", response);
      setApiAllData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  // Fetch diagrams by search query
  async function fetchDiagramBySearch(diagramName) {
    console.log("diagramName ===>", diagramName);
    try {
      const response = await axios.get(`https://mrk.elenta.co/visual/api/all-diagrams/?search=${diagramName}`);
      console.log(" response ===>", response);
      if (response?.data) {
        setApiAllData(response?.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  // Fetch diagram data and insert into state
  const fetchDataAndInsertDiagram = async (e, id) => {
    try {
      if (e?.diagram_type === "mermaid") {
        const response = await fetch(`https://mrk.elenta.co/visual/mermaid/${id}/`);
        const rawData = await response.text();
        const mermaidKeywords = [
          "classDiagram",
          "sequenceDiagram",
          "stateDiagram",
          "flowchart TD",
          "erDiagram",
          "gantt",
          "journey",
          "gitGraph",
          "pie",
          "mindmap",
          "quadrantChart",
          "xychart-beta",
          "block-beta",
          "zenuml",
          "flowchart",
        ];
        // Determine if diagram is mermaid or graphviz and set state accordingly
        const containsMermaidKeyword = mermaidKeywords.some((keyword) => rawData.includes(keyword));

        if (containsMermaidKeyword) {
          setDirectMermaidDaigram(rawData);
          setDirectGraphvizDaigram(null);
        } else {
          setDirectGraphvizDaigram(rawData);
          setDirectMermaidDaigram(null);
        }

        console.log("Diagram by ID (Raw Data):", rawData);
      } else {
        const response = await fetch(`https://mrk.elenta.co/visual/graphviz/${id}/`);
        const rawData = await response.text();
        const mermaidKeywords = [
          "classDiagram",
          "sequenceDiagram",
          "stateDiagram",
          "flowchart TD",
          "erDiagram",
          "gantt",
          "journey",
          "gitGraph",
          "pie",
          "mindmap",
          "quadrantChart",
          "xychart-beta",
          "block-beta",
          "zenuml",
          "flowchart",
        ];
        // Determine if diagram is mermaid or graphviz and set state accordingly
        const containsMermaidKeyword = mermaidKeywords.some((keyword) => rawData.includes(keyword));

        if (containsMermaidKeyword) {
          setDirectMermaidDaigram(rawData);
          setDirectGraphvizDaigram(null);
        } else {
          setDirectGraphvizDaigram(rawData);
          setDirectMermaidDaigram(null);
        }

        console.log("Diagram by ID (Raw Data):", rawData);
      }
    } catch (error) {
      console.error("Error fetching diagram by ID:", error);
    }
  };

  // Handle opening modal with diagram data
  const handleGetByIdGraphvizApi = (id, e) => {
    console.log("card id ===>", e);
    setModalCard(true);
    setModalData(e);
    fetchDataAndInsertDiagram(e, id);
  };
  // Handle closing modal
  const handleCardCloseModal = () => {
    setModalCard(false);
  };

  return (
    <>
      <Header
        insertDiagramAsImage={insertDiagramAsImage}
        OneDriveLocalData={OneDriveLocalData}
        setOneDriveFiles={setOneDriveFiles}
        oneDriveFiles={oneDriveFiles}
        setGithubShareModal={setGithubShareModal}
        handleToggleChange={handleToggleChange}
      />
      <div style={{ height: "88.3vh", backgroundColor: backgroundColor, overflowY: "auto", paddingBottom: 12 }}>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          alignItems="center"
          alignContent="center"
          gap={3}
          marginTop={3}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
          sx={{
            "&:hover": {
              backgroundColor: "transparent",
              boxShadow: "none",
            },
          }}
        >
          {/* Multiple buttons */}
          <div style={styles.container}>
            <div style={styles.row}>
              <PrimaryButton text="All" style={styles.button} onClick={fetchData} />
              <PrimaryButton text="Flowchart" style={styles.button} onClick={() => fetchDiagramBySearch("flowchart")} />
              <PrimaryButton text="Sequence" style={styles.button} onClick={() => fetchDiagramBySearch("sequence")} />
            </div>
            <div style={styles.row}>
              <PrimaryButton
                text="Gantt Chart"
                style={styles.button}
                onClick={() => fetchDiagramBySearch("ganttchart")}
              />
              <PrimaryButton
                text="Class Diagram"
                style={styles.button}
                onClick={() => fetchDiagramBySearch("classdiagram")}
              />
              <PrimaryButton text="Git Graph" style={styles.button} onClick={() => fetchDiagramBySearch("gitgraph")} />
            </div>

            <div style={styles.row}>
              <PrimaryButton text="Mindmap" style={styles.button} onClick={() => fetchDiagramBySearch("mindmap")} />
              <PrimaryButton text="Pie Chart" style={styles.button} onClick={() => fetchDiagramBySearch("piechart")} />
              <PrimaryButton
                text="Quadrant Chart"
                style={styles.button}
                onClick={() => fetchDiagramBySearch("quadrantchart")}
              />
            </div>
            <div style={styles.row}>
              <PrimaryButton text="Block" style={styles.button} onClick={() => fetchDiagramBySearch("block")} />
              <PrimaryButton
                text="C4 Diagram"
                style={styles.button}
                onClick={() => fetchDiagramBySearch("c4diagram")}
              />
              <PrimaryButton
                text="ER Diagram"
                style={styles.button}
                onClick={() => fetchDiagramBySearch("erdiagram")}
              />
            </div>

            <div style={styles.row}>
              <PrimaryButton
                text="Requirement Diagram"
                style={styles.button}
                onClick={() => fetchDiagramBySearch("requirementdiagram")}
              />
              <PrimaryButton
                text="Sankey Diagram"
                style={styles.button}
                onClick={() => fetchDiagramBySearch("sankeydiagram")}
              />
              <PrimaryButton
                text="State Diagram"
                style={styles.button}
                onClick={() => fetchDiagramBySearch("stateDiagram")}
              />
            </div>
            <div style={styles.row}>
              <PrimaryButton text="Timeline" style={styles.button} onClick={() => fetchDiagramBySearch("timeline")} />
              <PrimaryButton
                text="User Journey"
                style={styles.button}
                onClick={() => fetchDiagramBySearch("userjourney")}
              />
              <PrimaryButton
                text="UML diagram"
                style={styles.button}
                onClick={() => fetchDiagramBySearch("umlclassdiagram")}
              />
            </div>
            <div style={styles.row}>
              <PrimaryButton text="Basic Git" style={styles.button} onClick={() => fetchDiagramBySearch("basicgit")} />
              <PrimaryButton
                text="Bazel Build"
                style={styles.button}
                onClick={() => fetchDiagramBySearch("bazelbuild")}
              />
              <PrimaryButton
                text="Cluster Gradient"
                style={styles.button}
                onClick={() => fetchDiagramBySearch("clustergradient")}
              />
            </div>
            <div style={styles.row}>
              <PrimaryButton text="Cluster" style={styles.button} onClick={() => fetchDiagramBySearch("cluster")} />
              <PrimaryButton
                text="Cluster relations"
                style={styles.button}
                onClick={() => fetchDiagramBySearch("clusterrelations")}
              />
              <PrimaryButton
                text="Data Structures"
                style={styles.button}
                onClick={() => fetchDiagramBySearch("datastructures")}
              />
            </div>
            <div style={styles.row}>
              <PrimaryButton
                text="Color Wheel"
                style={styles.button}
                onClick={() => fetchDiagramBySearch("colorwheel")}
              />
              <PrimaryButton
                text="Entity-Relation"
                style={styles.button}
                onClick={() => fetchDiagramBySearch("entityrelation")}
              />
              <PrimaryButton
                text="Family Tree"
                style={styles.button}
                onClick={() => fetchDiagramBySearch("familytree")}
              />
            </div>
            <div style={styles.row}>
              <PrimaryButton
                text="Finite Automation"
                style={styles.button}
                onClick={() => fetchDiagramBySearch("finiteautomation")}
              />
              <PrimaryButton
                text="Go Package"
                style={styles.button}
                onClick={() => fetchDiagramBySearch("gopackage")}
              />
              <PrimaryButton
                text="Gradient Linear"
                style={styles.button}
                onClick={() => fetchDiagramBySearch("gradientlinear")}
              />
            </div>
            <div style={styles.row}>
              <PrimaryButton
                text="Gradient Radial"
                style={styles.button}
                onClick={() => fetchDiagramBySearch("gradientradial")}
              />
              <PrimaryButton
                text="Gradient Applied"
                style={styles.button}
                onClick={() => fetchDiagramBySearch("gradientapplied")}
              />
              <PrimaryButton
                text="Graph Cluster"
                style={styles.button}
                onClick={() => fetchDiagramBySearch("graphcluster")}
              />
            </div>
            <div style={styles.row}>
              <PrimaryButton
                text="Hello World"
                style={styles.button}
                onClick={() => fetchDiagramBySearch("helloworld")}
              />
              <PrimaryButton text="Grid" style={styles.button} onClick={() => fetchDiagramBySearch("grid")} />
              <PrimaryButton
                text="Intranet Layout"
                style={styles.button}
                onClick={() => fetchDiagramBySearch("intranetlayout")}
              />
            </div>
            <div style={styles.row}>
              <PrimaryButton
                text="Linear Radial"
                style={styles.button}
                onClick={() => fetchDiagramBySearch("linearradial")}
              />
              <PrimaryButton text="Linux" style={styles.button} onClick={() => fetchDiagramBySearch("linux")} />
              <PrimaryButton
                text="Math Parse"
                style={styles.button}
                onClick={() => fetchDiagramBySearch("mathparse")}
              />
            </div>
            <div style={styles.row}>
              <PrimaryButton text="PProf CPU" style={styles.button} onClick={() => fetchDiagramBySearch("PProf CPU")} />
              <PrimaryButton text="Table Cell" style={styles.button} onClick={() => fetchDiagramBySearch("linux")} />
              <PrimaryButton
                text="Switch Network"
                style={styles.button}
                onClick={() => fetchDiagramBySearch("switchnetwork")}
              />
            </div>
          </div>

          {/* Card section */}
          {apiAllData?.map((e, index) => (
            <Grid item xs={12}>
              <Grid container justifyContent="center" spacing={2}>
                <Grid item>
                  <div style={{ maxWidth: 250, cursor: "pointer" }} onClick={() => handleGetByIdGraphvizApi(e?.id, e)}>
                    <Card>
                      <CardMedia component="img" height="140" image={e?.image} />
                      <CardContent style={{ padding: 4 }}>
                        <Typography variant="body2" color="text.secondary">
                          {e?.title}
                        </Typography>
                      </CardContent>
                    </Card>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </div>
      {modalData && (
        <CardModal
          modalData={modalData}
          handleCardCloseModal={handleCardCloseModal}
          modalCard={modalCard}
          directMermaidDaigram={directMermaidDaigram}
          directGraphvizDaigram={directGraphvizDaigram}
          handleToggleChange={handleToggleChange}
        />
      )}
      <Footer />
    </>
  );
}

export default OnlineTemplate;
