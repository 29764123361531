import React, { useContext, useEffect, useState } from "react";
import { TextField, Modal } from "@fluentui/react";
import mermaid from "mermaid";
import Editor from "@monaco-editor/react";
import { Stack, Text } from "office-ui-fabric-react";
import Viz from "viz.js";
import { Module, render } from "viz.js/full.render.js";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import { useTheme } from "../themeContext/ThemeContext";
import TemplateScreenModal from "../templateScreenModal/TemplateScreenModal";
import { GlobalContext } from "../../../context/global";
import OnlineTemplate from "../onlineTemplate/OnlineTemplate";

/**
 * Component for handling text editing and diagram insertion.
 */

const Extentsion = () => {
  const {
    galleryModal,
    inputValue,
    setInputValue,
    markdown,
    setMarkdown,
    graphvizCode,
    setGraphvizCode,
    isSwitchChecked,
    setIsSwitchChecked,
  } = useContext(GlobalContext);

  const [isDarkMode, setIsDarkMode] = useState(false);
  const [oneDriveShareModal, setOneDriveShareModal] = useState(false);
  const [githubShareModal, setGithubShareModal] = useState(false);

  const [oneDriveFiles, setOneDriveFiles] = useState([]);
  const { currentTheme } = useTheme();

  /**
   * Handles changes in the editor input value.
   * Determines if the input contains Mermaid or Graphviz code and updates state accordingly.
   * @param {string} value - The new input value from the editor.
   */
  const handleChange = (value, e) => {
    setInputValue(value);

    const mermaidKeywords = [
      "classDiagram",
      "sequenceDiagram",
      "stateDiagram",
      "flowchart TD",
      "erDiagram",
      "gantt",
      "journey",
      "gitGraph",
      "pie",
      "mindmap",
      "quadrantChart",
      "xychart-beta",
      "zenuml",
      "block-beta",
      "flowchart",
      "flowchart LR",
    ];

    // Check if the value starts with any of the Mermaid keywords
    const isMermaidCode = mermaidKeywords.some((keyword) => value.toLowerCase().startsWith(keyword.toLowerCase()));

    if (isMermaidCode) {
      setMarkdown(value);
      localStorage.setItem("markdown", value);
      setGraphvizCode("");
    } else {
      setGraphvizCode(value);
      localStorage.setItem("graphviz", value);
      setMarkdown("");
    }

    localStorage.setItem("inputValue", value);
    console.log("value ==>", value);
  };

  useEffect(() => {
    // Load the initial value from localStorage when the component mounts
    const savedMarkdown = localStorage.getItem("markdown");
    if (savedMarkdown) {
      setMarkdown(savedMarkdown);
    }

    const AddGraphState = localStorage.getItem("graphviz");
    if (AddGraphState) {
      setGraphvizCode(AddGraphState);
    }
  }, []);

  /**
   * Inserts the current diagram as an image into the document.
   * Handles both Mermaid and Graphviz diagrams.
   */
  const insertDiagramAsImage = () => {
    if (markdown) {
      console.log("markdown code in condition ==>", markdown);
      // Handle Mermaid diagram
      const diagramImage = convertMermaidToImage(markdown);
    } else if (graphvizCode) {
      // Handle Graphviz diagram
      const viz = new Viz({ Module, render });
      viz
        .renderString(graphvizCode)
        .then((graphvizResult) => {
          handleDiagramInsertion(graphvizResult);
        })
        .catch((graphvizError) => {
          console.log("Error rendering Graphviz code:", graphvizError);
        });
    } else {
      console.log("No diagram code found.");
    }
    setGraphvizCode("");
    setInputValue("");
  };

  /**
   * Handles the insertion of a Graphviz diagram into the document.
   * @param {string} graphvizResult - The rendered Graphviz SVG or image data.
   */

  const handleDiagramInsertion = (graphvizResult) => {
    // Handle Graphviz SVG
    // Parse the XML result to get the SVG content
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(graphvizResult, "text/xml");
    const svgContent = xmlDoc.querySelector("svg").outerHTML;
    console.log("svg Content ===>", svgContent);
    if (Office.context.host === Office.HostType.PowerPoint) {
      // Check if PowerPoint is initialized
      if (Office.context.document) {
        Office.context.document.setSelectedDataAsync(
          svgContent,
          {
            coercionType: Office.CoercionType.XmlSvg,
            imageLeft: 50,
            imageTop: 50,
            imageWidth: 400,
          },
          (asyncResult) => {
            if (asyncResult.status === Office.AsyncResultStatus.Failed) {
              console.log("Error setting selected data:", asyncResult.error.message);
            }
            setGraphvizCode("");
            setInputValue("");
          }
        );
      } else {
        console.log("PowerPoint is not yet initialized.");
      }
    } else {
      console.log("This code is intended for PowerPoint add-ins.");
    }
    setGraphvizCode("");
    setInputValue("");
  };

  let svgContentState = null;

  /**
   * Converts the Mermaid code to an SVG representation and inserts it into the document.
   * @param {string} mermaidCode - The Mermaid code to convert and insert.
   */
  const convertMermaidToImage = (mermaidCode) => {
    try {
      console.log("mermaid code ==>", mermaidCode);
      // Convert the Mermaid code to an SVG representation
      mermaidCode = parseMermaidCode(mermaidCode);

      mermaid.render("mermaid", mermaidCode).then((svgImage) => {
        // Access the SVG string from the 'svg' property
        const svgString = svgImage.svg;

        // Update the state variable with the SVG content
        if (svgString) {
          // Delete the old value if it exists
          localStorage.removeItem("mermaidSvg");

          // Set the new value
          svgContentState = localStorage.setItem("mermaidSvg", svgString) || svgString;
        }

        console.log("mermaid chart ==>", svgString);

        // Wrap the SVG string in a <pre> tag
        const svgStringWithPreTag = `<pre class="mermaid">${svgString}</pre>`;
        // ... Rest of your code
        Office.context.document.setSelectedDataAsync(
          svgString,
          {
            coercionType: Office.CoercionType.XmlSvg,
            imageLeft: 50,
            imageTop: 50,
            imageWidth: 400,
          },

          function (asyncResult) {
            if (asyncResult.status === Office.AsyncResultStatus.Failed) {
              console.log(asyncResult.error.message);
              localStorage.removeItem("mermaidSvg");
              localStorage.removeItem("markdown");
              setInputValue("");
              setMarkdown("");
              setGraphvizCode("");
            } else {
              setInputValue("");
              localStorage.removeItem("mermaidSvg");
              localStorage.removeItem("markdown");
              setMarkdown("");
              setGraphvizCode("");
            }
          }
        );
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  /**
   * Custom parser for replacing FontAwesome icons with PowerPoint-compatible icons in Mermaid diagrams.
   * @param {string} mermaidCode - The Mermaid code to parse.
   * @returns {string} - The modified Mermaid code with PowerPoint-compatible icons.
   */
  // Custom Parser
  function parseMermaidCode(mermaidCode) {
    for (const faIcon in iconMapping) {
      const pptIcon = iconMapping[faIcon];
      mermaidCode = mermaidCode.replace(new RegExp(faIcon, "g"), `${pptIcon}`);
    }
    return mermaidCode;
  }

  const iconMapping = {
    "fa:fa-car": "**",
    "::icon(fa fa-book)": "&**",
    // Add more mappings for other icons
  };

  // console.log("markdown ===>", markdown);

  // Configuration for Mermaid diagrams

  const config = {
    theme: "neutral",
    startOnLoad: true,
    arrowMarkerAbsolute: false,
    logLevel: "debug",
    securityLevel: "strict",
    themeVariables: {
      // commitLabelColor: "#ff0000",
      commitLabelBackground: "#8E8E92",
      commitLabelFontSize: "16px",
      git0: "#0000ec",
      git1: "#DEDE00",
      git2: "#0000ff",
      git3: "#ff00ff",
      git4: "#00ffff",
      git5: "#ffff00",
      git6: "#ff00ff",
      git7: "#00ffff",
      gitInv0: "#ff0000",
      gitBranchLabel0: "#ffffff",
      gitBranchLabel1: "#000",
      pieOuterStrokeWidth: "5px",
    },
    er: {
      diagramPadding: 20,
      layoutDirection: "TB",
      minEntityWidth: 100,
      minEntityHeight: 75,
      entityPadding: 15,
      stroke: "gray",
      fill: "honeydew",
      fontSize: 12,
      useMaxWidth: true,
    },
    flowchart: {
      // theme:'base',
      diagramPadding: 8,
      htmlLabels: false,
      curve: "basis",
    },
    sequence: {
      diagramMarginX: 50,
      diagramMarginY: 10,
      actorMargin: 50,
      width: 150,
      height: 65,
      boxMargin: 10,
      boxTextMargin: 5,
      noteMargin: 10,
      messageMargin: 35,
      messageAlign: "center",
      mirrorActors: true,
      bottomMarginAdj: 1,
      useMaxWidth: true,
      rightAngles: false,
      showSequenceNumbers: false,
    },

    gantt: {
      theme: "base",
      titleTopMargin: 25,
      barHeight: 20,
      barGap: 50,
      topPadding: 50,
      leftPadding: 25,
      gridLineStartPadding: 25,
      fontSize: 11,
      fontFamily: '"Open Sans", sans-serif',
      numberSectionStyles: 10,
      axisFormat: "%Y-%m-%d",
      topAxis: false,
      displayMode: "",
    },
    journey: {
      useMaxWidth: true,
      taskFontFamily: "Arial, sans-serif",
      taskFontSize: 14,
      rightAngles: true,
      textPlacement: "right",
      boxMargin: 10,
      boxTextMargin: 5,
      diagramMarginX: 30,
      diagramMarginY: 20,
      leftMargin: 100,
      bottomMarginAdj: 5,
      scale: 1,
    },
    gitGraph: {
      theme: "neutral",
      showBranches: true,
      rotateCommitLabel: true,
      showCommitLabel: true,
      // mode: "extended",
      mainBranchOrder: 0,
      mainBranchName: "main",
      arrowMarkerAbsolute: false,
    },
    quadrantChart: {
      chartWidth: 500,
      chartHeight: 500,
      quadrantTextTopPadding: 30,
      xAxisLabelPadding: 20,
      yAxisLabelPadding: 20,
      pointLabelFontSize: 10,
      titlePadding: 20,
      pointTextPadding: 13,
    },
    pie: { textPosition: 0.75, useWidth: 500, useMaxWidth: true },
  };
  useEffect(() => {
    // Initialize Mermaid with the configuration
    mermaid.mermaidAPI.initialize(config);
  }, [insertDiagramAsImage]);

  /**
   * Handles closing the GitHub share modal.
   */

  const handleGithubShareCloseModal = () => {
    setGithubShareModal(false);
  };

  /**
   * Handles closing the OneDrive share modal.
   */

  const handleOneDriveCloseModal = () => {
    setOneDriveShareModal(false);
  };

  // Local data retrieval
  const OneDriveLocalData = localStorage.getItem("oneDrive_data");

  /**
   * Toggles the state of the switch.
   */
  const handleToggleChange = () => {
    // setIsSwitchChecked((prevState) => {
    //   const newState = !prevState;
    //   console.log("checked ===>", newState);
    //   return newState;
    // });
    setIsSwitchChecked(!isSwitchChecked);
  };

  // return !galleryModal ? (
  //   <div
  //     style={{
  //       minHeight: "100vh",
  //       backgroundColor: isDarkMode ? "black" : "white",
  //       display: "flex",
  //       flexDirection: "column",
  //     }}
  //   >
  //     {/* Header */}
  //     <Header
  //       insertDiagramAsImage={insertDiagramAsImage}
  //       OneDriveLocalData={OneDriveLocalData}
  //       setOneDriveFiles={setOneDriveFiles}
  //       oneDriveFiles={oneDriveFiles}
  //       setGithubShareModal={setGithubShareModal}
  //       handleToggleChange={handleToggleChange}
  //     />
  //     <div style={{ flex: 1, height: "100vh" }}>
  //       <div
  //         style={{
  //           display: "flex",
  //           flexDirection: "row",
  //           backgroundColor: isDarkMode ? "black" : "white",
  //         }}
  //       >
  //         <Editor
  //           height="88.3vh"
  //           language="markdown"
  //           defaultValue={inputValue}
  //           value={inputValue}
  //           theme={currentTheme}
  //           onChange={handleChange}
  //         />
  //       </div>
  //     </div>
  //     {/* Fixed Footer */}
  //     <Footer inputValue={inputValue} />
  //     <Modal
  //       containerClassName="modal-container custom-modal"
  //       styles={{
  //         main: {
  //           borderRadius: "15px",
  //           minHeight: 50,
  //         },
  //       }}
  //       isOpen={githubShareModal}
  //       onDismiss={handleGithubShareCloseModal}
  //       isBlocking={false}
  //     >
  //       <div style={{ padding: "20px" }}>
  //         <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
  //           <Text style={{ textAlign: "center" }} variant="xLarge">
  //             {""}
  //           </Text>
  //         </Stack>
  //       </div>
  //     </Modal>
  //     <Modal
  //       containerClassName="modal-container custom-modal"
  //       styles={{
  //         main: {
  //           borderRadius: "15px",
  //         },
  //       }}
  //       isOpen={oneDriveShareModal}
  //       onDismiss={handleOneDriveCloseModal}
  //       isBlocking={false}
  //     >
  //       <div style={{ padding: "20px" }}>
  //         <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
  //           <Text style={{ textAlign: "center" }} variant="xLarge">
  //             {""}
  //           </Text>
  //         </Stack>
  //       </div>
  //     </Modal>
  //   </div>
  // ) : isSwitchChecked ? (
  //   <OnlineTemplate />
  // ) : (
  //   <TemplateScreenModal />
  // );

  // Render different templates based on conditions
  return isSwitchChecked ? (
    <OnlineTemplate
      handleToggleChange={handleToggleChange}
      insertDiagramAsImage={insertDiagramAsImage}
      OneDriveLocalData={OneDriveLocalData}
      setOneDriveFiles={setOneDriveFiles}
      oneDriveFiles={oneDriveFiles}
      setGithubShareModal={setGithubShareModal}
    />
  ) : !galleryModal ? (
    <div
      style={{
        minHeight: "100vh",
        backgroundColor: isDarkMode ? "black" : "white",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* Header */}
      <Header
        insertDiagramAsImage={insertDiagramAsImage}
        OneDriveLocalData={OneDriveLocalData}
        setOneDriveFiles={setOneDriveFiles}
        oneDriveFiles={oneDriveFiles}
        setGithubShareModal={setGithubShareModal}
        handleToggleChange={handleToggleChange}
      />
      {/* Editor */}
      <div style={{ flex: 1, height: "100vh" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: isDarkMode ? "black" : "white",
          }}
        >
          <Editor
            height="88.5vh"
            language="markdown"
            defaultValue={inputValue}
            value={inputValue}
            theme={currentTheme}
            onChange={handleChange}
          />
        </div>
      </div>
      {/* Footer */}
      <Footer inputValue={inputValue} />
      {/* GitHub Share Modal */}
      <Modal
        containerClassName="modal-container custom-modal"
        styles={{
          main: {
            borderRadius: "15px",
            minHeight: 50,
          },
        }}
        isOpen={githubShareModal}
        onDismiss={handleGithubShareCloseModal}
        isBlocking={false}
      >
        <div style={{ padding: "20px" }}>
          <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
            <Text style={{ textAlign: "center" }} variant="xLarge">
              {""}
            </Text>
          </Stack>
        </div>
      </Modal>
      {/* OneDrive Share Modal */}
      <Modal
        containerClassName="modal-container custom-modal"
        styles={{
          main: {
            borderRadius: "15px",
          },
        }}
        isOpen={oneDriveShareModal}
        onDismiss={handleOneDriveCloseModal}
        isBlocking={false}
      >
        <div style={{ padding: "20px" }}>
          <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
            <Text style={{ textAlign: "center" }} variant="xLarge">
              {""}
            </Text>
          </Stack>
        </div>
      </Modal>
    </div>
  ) : (
    <TemplateScreenModal />
  );
};

export default Extentsion;
