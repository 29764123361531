import React, { createContext, useContext, useState, useEffect } from "react";

// Creating a theme context to manage current theme state and dark mode toggle

const ThemeContext = createContext();
// ThemeProvider component manages theme state and provides it to the app
export const ThemeProvider = ({ children }) => {
  // Available themes for the application
  const themes = ["vs-light", "vs-dark", "hc-black"];
  // State to manage current and next themes
  const [currentTheme, setCurrentTheme] = useState(themes[0]);
  const [nextTheme, setNextTheme] = useState(themes[1]);

  // Initialize next theme on component mount
  useEffect(() => {
    setNextTheme(themes[1]);
  }, []);

  // Function to toggle dark mode between available themes
  const toggleDarkMode = () => {
    // Reset next theme if it's not in the list of available themes
    if (!themes.includes(nextTheme)) {
      setNextTheme(themes[0]);
    }
    // Set current theme and determine next theme in sequence
    setCurrentTheme(nextTheme);
    setNextTheme(themes[(themes.indexOf(nextTheme) + 1) % themes.length]);
  };

  // Value provided by the context includes current theme and toggle function
  const contextValue = {
    currentTheme,
    toggleDarkMode,
  };
  // Provide the context value to the entire application using ThemeContext.Provider
  return <ThemeContext.Provider value={contextValue}>{children}</ThemeContext.Provider>;
};

// Custom hook to access the theme context anywhere in the application
export const useTheme = () => {
  // Ensure useTheme is used within a ThemeProvider, retrieve context
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};
