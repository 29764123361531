import React, { useContext, useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Header from "../header/Header";
import Dialog from "@mui/material/Dialog";
import { PrimaryButton, Text, Image } from "office-ui-fabric-react";
import { Grid, Card, CardMedia, CardContent, Typography } from "@mui/material";
import Footer from "../footer/Footer";
import { useTheme } from "../themeContext/ThemeContext";
import axios from "axios";
import { GlobalContext } from "../../../context/global";
import CardModal from "../../modalPopup/CardModal";
import { Search24Regular } from "@fluentui/react-icons";
import { Dismiss20Regular } from "@fluentui/react-icons";

const TemplateScreenModal = () => {
  // Context variables from global context
  const { galleryModal, setGalleryModal, setShowPopup } = useContext(GlobalContext);

  // State variables initialization
  const [searchValue, setSearchValue] = useState("");
  const [activeButton, setActiveButton] = useState("graphviz");

  const [activeDiagramType, setActiveDiagramType] = useState("Sequence Diagram");
  const [activeGraphvizDiagramType, setActiveGraphvizDiagramType] = useState("Traffic Diagram");
  const { currentTheme } = useTheme();
  const isDarkTheme = currentTheme === "vs-dark";
  const isHighContrastTheme = currentTheme === "hc-black";
  // State variables for API data and modal handling
  const [apiData, setApiData] = useState([]);
  const [apiMermaidDiagram, setMermaidDiagram] = useState([]);
  const [apiSaveMermaidDiagram, setApiSaveMermaidDiagram] = useState([]);
  const [apiGolbalSearch, setApiGolbalSearch] = useState([]);
  const [modalCard, setModalCard] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [directMermaidDaigram, setDirectMermaidDaigram] = useState("");
  const [directGraphvizDaigram, setDirectGraphvizDaigram] = useState("");
  const [apiAllData, setApiAllData] = useState([]);

  // Function to get background color based on theme
  const getBackgroundColor = () => {
    if (isDarkTheme) {
      return "black";
    } else if (isHighContrastTheme) {
      return "#000000";
    } else {
      return "white";
    }
  };
  let backgroundColor;

  // Assigning background color based on current theme
  if (currentTheme === "vs-light") {
    backgroundColor = "white";
  } else if (currentTheme === "vs-dark") {
    backgroundColor = "#212529";
  } else if (currentTheme === "hc-black") {
    backgroundColor = "#000";
  }
  // Event handlers for diagram type and search
  const handleDiagramTypeClick = (diagramType) => {
    setActiveDiagramType(diagramType);
  };

  const handleGraphviz = (diagramType) => {
    setActiveGraphvizDiagramType(diagramType);
  };
  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType);
  };

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchValue(value);
    debounceSearch(value);
  };

  // Debounce function for delaying search requests
  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), delay);
    };
  };

  const browseGetTextColor = () => {
    if (isDarkTheme) {
      return "white";
    } else if (isHighContrastTheme) {
      return "#A59B17";
    } else {
      return "#B6472B";
    }
  };
  // Function to get text color based on theme

  const getTextColor = () => {
    if (isDarkTheme) {
      return "white";
    } else if (isHighContrastTheme) {
      return "#A59B17";
    } else {
      return "grey";
    }
  };
  // async function fetchData() {
  //   try {
  //     const response = await axios.get("https://mrk.elenta.co/visual/graph-diagrams/");
  //     console.log(" graph response ===>", response);
  //     setApiData(response?.data);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // }
  // Fetching initial data for mermaid diagrams on component mount
  async function fetchMermaidDiagram() {
    handleButtonClick("mermaid");
    try {
      const response = await axios.get("https://mrk.elenta.co/visual/mermaid-diagrams/");
      console.log("response ===>", response);
      setMermaidDiagram(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    fetchMermaidDiagram();
  }, []);

  // Close modal handler
  const handleClose = () => {
    setGalleryModal(false);
  };

  const handleGetByIdGraphvizApi = (id, e) => {
    console.log("card id ===>", e);
    setModalCard(true);
    setModalData(e);
    // openWin(e);
    fetchDataAndInsertDiagram(e, id);
  };

  const spinnerStyles = {
    circle: {
      borderColor: "#B6472B",
    },
    label: {
      color: "#B6472B",
    },
  };

  // Handling API calls to save mermaid diagrams
  async function saveMermaidDiagrams() {
    handleButtonClick("templates");
    const GithubAccessToken = localStorage.getItem("token");
    const OneDriveApiToken = localStorage.getItem("oneDrive_token");

    try {
      const params = new URLSearchParams();

      // Check if the user is logged in with GitHub
      if (GithubAccessToken) {
        params.append("github_token", GithubAccessToken);
      }
      // Check if the user is logged in with OneDrive
      else if (OneDriveApiToken) {
        params.append("onedrive_token", OneDriveApiToken);
      } else {
        // Handle the case where neither GitHub nor OneDrive token is available
        console.error("Neither GitHub nor OneDrive token is available");
        return;
      }

      console.log("params ==>", params.toString());

      const response = await axios.get("https://mrk.elenta.co/visual/favorite_diagrams/", {
        params: params,
      });

      console.log("response get save Diagrams ===>", response);
      setApiSaveMermaidDiagram(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  // Getting OneDrive token from local storage
  const OneDriveApiToken = localStorage.getItem("oneDrive_token");
  const GithubAccessToken = localStorage.getItem("token");

  // Saving post Graphviz diagrams and updating API data
  async function savePostGraphvizDiagrams(id) {
    console.log("id ==>", id);

    const GithubAccessToken = localStorage.getItem("token");

    try {
      const formData = new FormData();

      formData.append("graph", id);

      // Check if either OneDriveApiToken or GithubAccessToken is present
      if (OneDriveApiToken || GithubAccessToken) {
        if (GithubAccessToken) {
          formData.append("github_token", GithubAccessToken);
        } else if (OneDriveApiToken) {
          formData.append("onedrive_token", OneDriveApiToken);
        }

        let isFound = apiData.find((e) => e.id == id).status;

        if (!isFound) {
          // dislike
          const response = await axios.post("https://mrk.elenta.co/visual/favorite_diagrams/", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          console.log("response save Diagrams ===>", response);
          if (response.data) {
            setApiData((prev) => prev.map((e) => (e.id == id ? { ...e, status: true } : e)));
          }
        } else {
          // like
          const response = await axios.delete("https://mrk.elenta.co/visual/favorite_diagrams/", {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            params: {
              graph: id,
              github_token: GithubAccessToken,
              onedrive_token: OneDriveApiToken,
            },
          });
          console.log("response save Diagrams ===>", response);
          if (response.data) {
            setApiData((prev) => prev.map((e) => (e.id == id ? { ...e, status: false } : e)));
          }
        }
      } else {
        setShowPopup(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  // Saving post mermaid diagrams and updating API data
  async function savePostMermaidDiagrams(id) {
    console.log("id ==>", id);
    const GithubAccessToken = localStorage.getItem("token");

    try {
      const formData = new FormData();
      formData.append("flowchart", id);

      if (OneDriveApiToken || GithubAccessToken) {
        // Checking for GitHub or OneDrive token and updating API data
        if (GithubAccessToken) {
          formData.append("github_token", GithubAccessToken);
        } else if (OneDriveApiToken) {
          formData.append("onedrive_token", OneDriveApiToken);
        }

        let isFound = apiMermaidDiagram.find((e) => e.id == id).status;

        if (!isFound) {
          // dislike
          const response = await axios.post("https://mrk.elenta.co/visual/favorite_diagrams/", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });

          console.log("response save Diagrams ===>", response);
          if (response.data) {
            setMermaidDiagram((prev) => prev.map((e) => (e.id == id ? { ...e, status: true } : e)));
          }
        } else {
          // like
          const response = await axios.delete("https://mrk.elenta.co/visual/favorite_diagrams/", {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            params: {
              flowchart: id,
              github_token: GithubAccessToken,
              onedrive_token: OneDriveApiToken,
            },
          });
          console.log("response save Diagrams ===>", response);
          if (response.data) {
            setMermaidDiagram((prev) => prev.map((e) => (e.id == id ? { ...e, status: false } : e)));
          }
        }
      } else {
        setShowPopup(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  // Debounced search function to delay API requests
  const debounceSearch = debounce((value) => {
    golbalSearchAPi(value);
  }, 2000);

  // API call for global search functionality
  const golbalSearchAPi = async (searchTerm) => {
    try {
      const response = await axios.get(`https://mrk.elenta.co/global-search/?title=${searchTerm}`, {});

      const data = response.data;
      setApiGolbalSearch(response?.data);

      // Logging the retrieved data
      console.log("Global search data ===>", data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Closing modal handler
  const handleCardCloseModal = () => {
    setModalCard(false);
  };

  // Fetching and inserting diagram based on ID
  const fetchDataAndInsertDiagram = async (e, id) => {
    try {
      if (e?.diagram_type === "mermaid") {
        const response = await fetch(`https://mrk.elenta.co/visual/mermaid/${id}/`);
        const rawData = await response.text();
        const mermaidKeywords = [
          "classDiagram",
          "sequenceDiagram",
          "stateDiagram",
          "flowchart TD",
          "erDiagram",
          "gantt",
          "journey",
          "gitGraph",
          "pie",
          "mindmap",
          "quadrantChart",
          "xychart-beta",
          "block-beta",
          "zenuml",
          "flowchart",
        ];

        const containsMermaidKeyword = mermaidKeywords.some((keyword) => rawData.includes(keyword));

        if (containsMermaidKeyword) {
          setDirectMermaidDaigram(rawData);
          setDirectGraphvizDaigram(null);
        } else {
          setDirectGraphvizDaigram(rawData);
          setDirectMermaidDaigram(null);
        }

        console.log("Diagram by ID (Raw Data):", rawData);
      } else {
        const response = await fetch(`https://mrk.elenta.co/visual/graphviz/${id}/`);
        const rawData = await response.text();
        const mermaidKeywords = [
          "classDiagram",
          "sequenceDiagram",
          "stateDiagram",
          "flowchart TD",
          "erDiagram",
          "gantt",
          "journey",
          "gitGraph",
          "pie",
          "mindmap",
          "quadrantChart",
          "xychart-beta",
          "block-beta",
          "zenuml",
          "flowchart",
        ];

        const containsMermaidKeyword = mermaidKeywords.some((keyword) => rawData.includes(keyword));

        if (containsMermaidKeyword) {
          setDirectMermaidDaigram(rawData);
          setDirectGraphvizDaigram(null);
        } else {
          setDirectGraphvizDaigram(rawData);
          setDirectMermaidDaigram(null);
        }

        console.log("Diagram by ID (Raw Data):", rawData);
      }
    } catch (error) {
      console.error("Error fetching diagram by ID:", error);
    }
  };

  async function fetchData() {
    try {
      const response = await axios.get("https://mrk.elenta.co/visual/api/all-diagrams/");
      console.log(" graph response ===>", response);
      setApiAllData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  async function fetchDiagramBySearch(diagramName) {
    console.log("diagramName ===>", diagramName);
    try {
      const response = await axios.get(`https://mrk.elenta.co/visual/api/all-diagrams/?search=${diagramName}`);
      console.log(" response ===>", response);
      if (response?.data) {
        setApiAllData(response?.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  // Styles for container and responsive layout
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      width: "72%",
    },
    row: {
      display: "flex",
      justifyContent: "space-between",
      gap: "10px",
    },
    button: {
      backgroundColor: "#B6472B",
      borderRadius: "8px",
      height: 35,
      fontSize: 11,
      minWidth: 79,
      border: "none",
      cursor: "pointer",
      flex: 1,
    },
    "@media (max-width: 600px)": {
      container: {
        width: "90%",
      },
      row: {
        flexDirection: "column",
        gap: "10px",
      },
    },
  };

  // Clearing search function
  const handleClearSearch = () => {
    setSearchValue("");
    setApiGolbalSearch([]);
  };

  // Rendering cards based on search results
  const renderCards = () => {
    const graphsDiagrams = apiGolbalSearch?.graphs_diagrams || [];
    const mermaidDiagrams = apiGolbalSearch?.mermaid_diagrams || [];

    const allDiagrams = [...graphsDiagrams, ...mermaidDiagrams];
    // Checking for matching diagrams and rendering them
    if (allDiagrams.length > 0) {
      const filteredDiagrams = allDiagrams.filter(
        (e) => e.title && e.title.toLowerCase().includes(searchValue?.toLowerCase())
      );
      // Displaying filtered diagrams
      if (filteredDiagrams.length > 0) {
        return filteredDiagrams.map((e, i) => (
          <Grid
            key={i}
            container
            spacing={2}
            direction="row"
            justify="center"
            alignItems="center"
            alignContent="center"
            gap={5}
            marginTop={3}
            style={{ display: "flex", justifyContent: "center", marginBottom: 12, height: "100%", cursor: "pointer" }}
          >
            <div style={{ maxWidth: 250 }}>
              <CardMedia
                height="140"
                style={{ borderWidth: 1, borderColor: "grey", borderStyle: "solid" }}
                onClick={() => handleGetByIdGraphvizApi(e.id, e)}
              >
                <Image src={e?.image} style={{ width: "100%", height: 150 }} />
              </CardMedia>
              <CardContent style={{ padding: 4 }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    fontFamily="Montserrat"
                    style={{ color: getTextColor() }}
                  >
                    {e.title}
                  </Typography>
                </div>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  fontFamily="Montserrat"
                  style={{
                    color: getTextColor(),
                  }}
                >
                  {e?.description}
                </Typography>
              </CardContent>
            </div>
          </Grid>
        ));
      } else {
        // No matching diagrams found message
        return (
          <div
            style={{
              textAlign: "center",
              marginTop: "20px",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <Typography
              variant="body2"
              color="text.secondary"
              fontFamily="Montserrat"
              style={{ color: getTextColor() }}
            >
              No matching diagrams found.
            </Typography>
          </div>
        );
      }
    } else {
      // No diagrams available message
      return (
        <div
          style={{
            textAlign: "center",
            marginTop: "20px",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <Typography variant="body2" color="text.secondary" fontFamily="Montserrat" style={{ color: getTextColor() }}>
            No diagrams available.
          </Typography>
        </div>
      );
    }
  };
  return (
    <Dialog
      fullScreen
      open={galleryModal}
      onClose={handleClose}
      PaperProps={{
        sx: {
          backgroundColor: backgroundColor,
        },
      }}
    >
      <AppBar sx={{ position: "relative" }}>
        <Header handleClose={handleClose} activeButton={activeButton} />
      </AppBar>
      <Text
        style={{
          fontSize: 16,
          fontWeight: "bold",
          fontFamily: "Montserrat",
          color: browseGetTextColor(),
          paddingLeft: 12,
          paddingTop: 13,
          paddingBottom: 10,
        }}
      >
        Browse by Categories
      </Text>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: "grey",
          marginTop: 8,
          width: "91%",
          minHeight: 40,
          alignSelf: "center",
          borderRadius: 10,
          color: "grey",
        }}
      >
        <Search24Regular
          style={{ marginLeft: "8px", color: "gray", cursor: "pointer" }}
          onClick={() => debounceSearch(searchValue)}
        />
        <input
          placeholder="Search"
          value={searchValue}
          onChange={handleSearchChange}
          style={{
            boxShadow: "none",
            borderWidth: 0,
            outlineWidth: 0,
            outlineStyle: "none",
            color: getTextColor(),
            backgroundColor: backgroundColor,
            width: "91%",
          }}
        />
        {searchValue && (
          <Dismiss20Regular
            style={{
              position: "absolute",
              right: "19px",
              color: "gray",
              cursor: "pointer",
            }}
            onClick={handleClearSearch}
          />
        )}
      </div>
      {searchValue ? (
        renderCards()
      ) : (
        <div style={{ height: "88.3vh", backgroundColor: backgroundColor, overflowY: "auto", paddingBottom: 12 }}>
          <Grid
            container
            spacing={2}
            direction="row"
            justify="center"
            alignItems="center"
            alignContent="center"
            gap={3}
            marginTop={3}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
            sx={{
              "&:hover": {
                backgroundColor: "transparent",
                boxShadow: "none",
              },
            }}
          >
            {/* Multiple buttons */}
            <div style={styles.container}>
              <div style={styles.row}>
                <PrimaryButton text="All" style={styles.button} onClick={fetchData} />
                <PrimaryButton
                  text="Flowchart"
                  style={styles.button}
                  onClick={() => fetchDiagramBySearch("flowchart")}
                />
                <PrimaryButton text="Sequence" style={styles.button} onClick={() => fetchDiagramBySearch("sequence")} />
              </div>
              <div style={styles.row}>
                <PrimaryButton
                  text="Gantt Chart"
                  style={styles.button}
                  onClick={() => fetchDiagramBySearch("ganttchart")}
                />
                <PrimaryButton
                  text="Class Diagram"
                  style={styles.button}
                  onClick={() => fetchDiagramBySearch("classdiagram")}
                />
                <PrimaryButton
                  text="Git Graph"
                  style={styles.button}
                  onClick={() => fetchDiagramBySearch("gitgraph")}
                />
              </div>

              <div style={styles.row}>
                <PrimaryButton text="Mindmap" style={styles.button} onClick={() => fetchDiagramBySearch("mindmap")} />
                <PrimaryButton
                  text="Pie Chart"
                  style={styles.button}
                  onClick={() => fetchDiagramBySearch("piechart")}
                />
                <PrimaryButton
                  text="Quadrant Chart"
                  style={styles.button}
                  onClick={() => fetchDiagramBySearch("quadrantchart")}
                />
              </div>
              <div style={styles.row}>
                <PrimaryButton text="Block" style={styles.button} onClick={() => fetchDiagramBySearch("block")} />
                <PrimaryButton
                  text="C4 Diagram"
                  style={styles.button}
                  onClick={() => fetchDiagramBySearch("c4diagram")}
                />
                <PrimaryButton
                  text="ER Diagram"
                  style={styles.button}
                  onClick={() => fetchDiagramBySearch("erdiagram")}
                />
              </div>

              <div style={styles.row}>
                <PrimaryButton
                  text="Requirement Diagram"
                  style={styles.button}
                  onClick={() => fetchDiagramBySearch("requirementdiagram")}
                />
                <PrimaryButton
                  text="Sankey Diagram"
                  style={styles.button}
                  onClick={() => fetchDiagramBySearch("sankeydiagram")}
                />
                <PrimaryButton
                  text="State Diagram"
                  style={styles.button}
                  onClick={() => fetchDiagramBySearch("stateDiagram")}
                />
              </div>
              <div style={styles.row}>
                <PrimaryButton text="Timeline" style={styles.button} onClick={() => fetchDiagramBySearch("timeline")} />
                <PrimaryButton
                  text="User Journey"
                  style={styles.button}
                  onClick={() => fetchDiagramBySearch("userjourney")}
                />
                <PrimaryButton
                  text="UML diagram"
                  style={styles.button}
                  onClick={() => fetchDiagramBySearch("umlclassdiagram")}
                />
              </div>
              <div style={styles.row}>
                <PrimaryButton
                  text="Basic Git"
                  style={styles.button}
                  onClick={() => fetchDiagramBySearch("basicgit")}
                />
                <PrimaryButton
                  text="Bazel Build"
                  style={styles.button}
                  onClick={() => fetchDiagramBySearch("bazelbuild")}
                />
                <PrimaryButton
                  text="Cluster Gradient"
                  style={styles.button}
                  onClick={() => fetchDiagramBySearch("clustergradient")}
                />
              </div>
              <div style={styles.row}>
                <PrimaryButton text="Cluster" style={styles.button} onClick={() => fetchDiagramBySearch("cluster")} />
                <PrimaryButton
                  text="Cluster relations"
                  style={styles.button}
                  onClick={() => fetchDiagramBySearch("clusterrelations")}
                />
                <PrimaryButton
                  text="Data Structures"
                  style={styles.button}
                  onClick={() => fetchDiagramBySearch("datastructures")}
                />
              </div>
              <div style={styles.row}>
                <PrimaryButton
                  text="Color Wheel"
                  style={styles.button}
                  onClick={() => fetchDiagramBySearch("colorwheel")}
                />
                <PrimaryButton
                  text="Entity-Relation"
                  style={styles.button}
                  onClick={() => fetchDiagramBySearch("entityrelation")}
                />
                <PrimaryButton
                  text="Family Tree"
                  style={styles.button}
                  onClick={() => fetchDiagramBySearch("familytree")}
                />
              </div>
              <div style={styles.row}>
                <PrimaryButton
                  text="Finite Automation"
                  style={styles.button}
                  onClick={() => fetchDiagramBySearch("finiteautomation")}
                />
                <PrimaryButton
                  text="Go Package"
                  style={styles.button}
                  onClick={() => fetchDiagramBySearch("gopackage")}
                />
                <PrimaryButton
                  text="Gradient Linear"
                  style={styles.button}
                  onClick={() => fetchDiagramBySearch("gradientlinear")}
                />
              </div>
              <div style={styles.row}>
                <PrimaryButton
                  text="Gradient Radial"
                  style={styles.button}
                  onClick={() => fetchDiagramBySearch("gradientradial")}
                />
                <PrimaryButton
                  text="Gradient Applied"
                  style={styles.button}
                  onClick={() => fetchDiagramBySearch("gradientapplied")}
                />
                <PrimaryButton
                  text="Graph Cluster"
                  style={styles.button}
                  onClick={() => fetchDiagramBySearch("graphcluster")}
                />
              </div>
              <div style={styles.row}>
                <PrimaryButton
                  text="Hello World"
                  style={styles.button}
                  onClick={() => fetchDiagramBySearch("helloworld")}
                />
                <PrimaryButton text="Grid" style={styles.button} onClick={() => fetchDiagramBySearch("grid")} />
                <PrimaryButton
                  text="Intranet Layout"
                  style={styles.button}
                  onClick={() => fetchDiagramBySearch("intranetlayout")}
                />
              </div>
              <div style={styles.row}>
                <PrimaryButton
                  text="Linear Radial"
                  style={styles.button}
                  onClick={() => fetchDiagramBySearch("linearradial")}
                />
                <PrimaryButton text="Linux" style={styles.button} onClick={() => fetchDiagramBySearch("linux")} />
                <PrimaryButton
                  text="Math Parse"
                  style={styles.button}
                  onClick={() => fetchDiagramBySearch("mathparse")}
                />
              </div>
              <div style={styles.row}>
                <PrimaryButton
                  text="PProf CPU"
                  style={styles.button}
                  onClick={() => fetchDiagramBySearch("PProf CPU")}
                />
                <PrimaryButton text="Table Cell" style={styles.button} onClick={() => fetchDiagramBySearch("linux")} />
                <PrimaryButton
                  text="Switch Network"
                  style={styles.button}
                  onClick={() => fetchDiagramBySearch("switchnetwork")}
                />
              </div>
            </div>

            {/* Card section */}
            {apiAllData?.map((e, index) => (
              <Grid item xs={12}>
                <Grid container justifyContent="center" spacing={2}>
                  <Grid item>
                    <div
                      style={{ maxWidth: 250, cursor: "pointer" }}
                      onClick={() => handleGetByIdGraphvizApi(e?.id, e)}
                    >
                      <Card>
                        <CardMedia component="img" height="140" image={e?.image} />
                        <CardContent style={{ padding: 4 }}>
                          <Typography variant="body2" color="text.secondary">
                            {e?.title}
                          </Typography>
                        </CardContent>
                      </Card>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </div>
      )}
      {modalData && (
        <CardModal
          modalData={modalData}
          handleCardCloseModal={handleCardCloseModal}
          modalCard={modalCard}
          directMermaidDaigram={directMermaidDaigram}
          directGraphvizDaigram={directGraphvizDaigram}
        />
      )}

      <div style={{ marginTop: 14 }}>
        <Footer />
      </div>
    </Dialog>
  );
};

export default TemplateScreenModal;
