import React, { useRef, useEffect } from "react";
import moon from "../../components/pics/moon.png";
import sun from "../../components/pics/sun.png";
import { Detector } from "react-detect-offline";
import { Stack } from "office-ui-fabric-react";
import { useTheme } from "../themeContext/ThemeContext";
import mermaid from "mermaid";
import Viz from "viz.js";
import { Module, render } from "viz.js/full.render.js";

const Footer = ({ inputValue }) => {
  // Theme and dark mode toggle context from ThemeContext
  const { currentTheme, toggleDarkMode } = useTheme();
  const themes = ["vs-light", "vs-dark", "hc-black"];
  const backgroundColor = "#b5462a";

  // Check if input contains any Mermaid syntax
  const isMermaid = [
    "classDiagram",
    "sequenceDiagram",
    "stateDiagram",
    "flowchart TD",
    "erDiagram",
    "gantt",
    "journey",
    "gitGraph",
    "pie",
    "mindmap",
    "quadrantChart",
    "xychart-beta",
    "block-beta",
    "zenuml",
    "flowchart",
  ].some((diagramType) => inputValue?.includes(diagramType));

  // Check if input contains any Graphviz syntax

  const isGraphviz = [
    "digraph",
    "graph",
    "subgraph",
    "node",
    "edge",
    "graphviz",
    "graphvis",
    "mermaid",
    "graphviz2",
    "graphvis2",
    "graphviz3",
    "graphvis3",
  ].some((diagramType) => inputValue?.includes(diagramType));

  // References for Mermaid and Graphviz rendering
  const mermaidRef = useRef(null);
  const graphvizRef = useRef(null);

  // Effect to render Mermaid diagram when input changes

  useEffect(() => {
    if (isMermaid && mermaidRef.current) {
      mermaid.mermaidAPI.render("mermaidDiagram", inputValue, (svgCode) => {
        mermaidRef.current.innerHTML = svgCode;
      });
    }
  }, [inputValue, isMermaid]);

  // Effect to render Graphviz diagram when input changes
  useEffect(() => {
    if (isGraphviz && graphvizRef.current) {
      const viz = new Viz({ Module, render });
      viz
        .renderString(inputValue)
        .then((graphvizResult) => {
          graphvizRef.current.innerHTML = graphvizResult;
        })
        .catch((error) => {
          console.error("Error rendering Graphviz:", error);
        });
    }
  }, [inputValue, isGraphviz]);

  // Style for online/offline indicator circle

  const circleStyle = {
    width: 10,
    height: 10,
    borderRadius: "50%",
    backgroundColor: "#E5E5EA",
    display: "inline-block",
    marginRight: 5,
  };
  // Style for online status text
  const onlineStatusStyle = {
    color: "white",
    fontSize: 12,
    fontFamily: "Montserrat",
    margin: 0,
    padding: 0,
  };

  return (
    <div style={{ backgroundColor, padding: 0 }}>
      <Stack horizontal verticalAlign="center">
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", flex: 1 }}>
          {/* Render Mermaid diagram indicator if applicable */}
          {isMermaid && (
            <div style={{ marginLeft: 11, height: 0 }}>
              {/* <div ref={mermaidRef} style={{ color: "white" }} /> */}
              <p style={{ color: "white", fontSize: 12, fontFamily: "Montserrat" }}>mermaid</p>
            </div>
          )}
          {/* Render Graphviz diagram indicator if applicable */}
          {isGraphviz && (
            <div style={{ marginLeft: 11, height: 0 }}>
              {/* <div ref={graphvizRef} style={{ color: "white" }} /> */}
              <p style={{ color: "white", fontSize: 12, fontFamily: "Montserrat" }}>graphviz</p>
            </div>
          )}
          {/* Render default message when no diagram syntax is detected */}
          {!isMermaid && !isGraphviz && (
            <div style={{ marginLeft: 11, height: 0 }}>
              <p style={{ color: "white", fontSize: 12, fontFamily: "Montserrat" }}>no code</p>
            </div>
          )}
          {/* Dark mode toggle and theme indicator */}
          <Stack.Item
            style={{
              marginLeft: "10px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Stack.Item>
              <Detector
                render={({ online }) => (
                  <div style={{ display: "flex", alignItems: "center", marginTop: 0 }}>
                    <div
                      style={{ ...circleStyle, backgroundColor: online ? "#34C759" : "#E5E5EA", marginRight: 10 }}
                    ></div>
                  </div>
                )}
              />
            </Stack.Item>
            <div
              className="Icon"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginRight: 35,
                cursor: "pointer",
              }}
              onClick={toggleDarkMode}
            >
              {/* Online/offline status indicator */}
              {currentTheme === themes[0] ? (
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                  <img src={sun} style={{ width: 18, height: 18, backgroundColor: "transparent", marginInline: 5 }} />
                  <p
                    style={{
                      lineHeight: 0,
                      margin: 0,
                      padding: 0,
                      textAlign: "center",
                      color: "white",
                      fontFamily: "Montserrat",
                      fontSize: 12,
                    }}
                  >
                    Light
                  </p>
                </div>
              ) : currentTheme === themes[1] ? (
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                  <img src={moon} style={{ width: 18, height: 18, backgroundColor: "transparent", marginInline: 5 }} />
                  <p
                    style={{
                      lineHeight: 0,
                      margin: 0,
                      padding: 0,
                      textAlign: "center",
                      color: "white",
                      fontFamily: "Montserrat",
                      fontSize: 12,
                    }}
                  >
                    Dark
                  </p>
                </div>
              ) : (
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                  <img src={moon} style={{ width: 18, height: 18, backgroundColor: "transparent", marginInline: 5 }} />
                  <p
                    style={{
                      lineHeight: 0,
                      margin: 0,
                      padding: 0,
                      textAlign: "center",
                      color: "white",
                      fontFamily: "Montserrat",
                      fontSize: 12,
                    }}
                  >
                    High Contrast
                  </p>
                </div>
              )}
            </div>
          </Stack.Item>
        </div>
      </Stack>
      {/* Uncomment below to display online/offline status text */}
      {/* <Stack.Item style={{ flex: 1, display: "flex", justifyContent: "flex-end", alignItems: "flex-end",marginRight:27, }}>
        <Detector
          render={({ online }) => (
            <div style={{ display: "flex", alignItems: "center", marginTop: 2 }}>
              <div style={{ ...circleStyle, backgroundColor: online ? "#34C759" : "#E5E5EA" }}></div>
              <p style={onlineStatusStyle}>{online ? "Online" : "Offline"}</p>
            </div>
          )}
        />
      </Stack.Item> */}
    </div>
  );
};

export default Footer;
