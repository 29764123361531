import React, { useState, createContext } from "react";

export const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [galleryModal, setGalleryModal] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [inputValue, setInputValue] = useState(localStorage.getItem("inputValue") || "");
  const [markdown, setMarkdown] = useState(localStorage.getItem("markdown") || "");
  const [graphvizCode, setGraphvizCode] = useState(localStorage.getItem("graphviz") || "");
  const [isSwitchChecked, setIsSwitchChecked] = useState(false);
  const [saveSvgInPc, setSaveSvgInPc] = useState(null);

  return (
    <GlobalContext.Provider
      value={{
        galleryModal: galleryModal,
        setGalleryModal: setGalleryModal,
        showPopup: showPopup,
        setShowPopup: setShowPopup,
        setInputValue: setInputValue,
        inputValue: inputValue,
        markdown: markdown,
        setMarkdown: setMarkdown,
        graphvizCode: graphvizCode,
        setGraphvizCode: setGraphvizCode,
        isSwitchChecked: isSwitchChecked,
        setIsSwitchChecked: setIsSwitchChecked,
        saveSvgInPc: saveSvgInPc,
        setSaveSvgInPc: setSaveSvgInPc,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
