import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

// Styles for the modal box
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  border: "1px solid #fff !important",
  boxShadow: 24,
  p: 4,
  textAlign: "center",
  borderRadius: "10px",
};

// SaveModal component for handling save actions
const SaveModal = ({ isOpen, onClose, onSaveAsPic, onSaveAsCode, saveAsSvgFile }) => {
  return (
    <Modal open={isOpen} onClose={onClose} style={{ outlineStyle: "none", borderColor: "#fff important" }}>
      <Box sx={style}>
        {/* Modal Title */}
        <Typography
          variant="h6"
          component="h2"
          style={{
            fontFamily: "Montserrat !important",
          }}
        >
          Save Diagram
        </Typography>
        {/* Button Group */}
        <Box sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            {/* Save as PNG Button */}
            <Button
              variant="contained"
              onClick={onSaveAsPic}
              sx={{
                width: "48%",
                height: "40px",
                fontFamily: "Montserrat !important",
                fontSize: "10px",
                backgroundColor: "#b5462a",
                "&:hover": {
                  backgroundColor: "#b5462a",
                },
              }}
            >
              Save as Png
            </Button>
            {/* Save as SVG Button */}
            <Button
              variant="contained"
              onClick={saveAsSvgFile}
              sx={{
                width: "48%",
                height: "40px",
                fontFamily: "Montserrat !important",
                fontSize: "10px",
                backgroundColor: "#b5462a",
                "&:hover": {
                  backgroundColor: "#b5462a",
                },
              }}
            >
              Save as Svg
            </Button>
          </Box>
          {/* Save as Code Button */}
          <Button
            variant="contained"
            onClick={onSaveAsCode}
            sx={{
              mt: 2,
              width: "100%",
              height: "40px",
              fontFamily: "Montserrat !important",
              fontSize: "10px",
              backgroundColor: "#b5462a",
              "&:hover": {
                backgroundColor: "#b5462a",
              },
            }}
          >
            Save as Code
          </Button>
          {/* Cancel Button */}
          <Button
            variant="outlined"
            onClick={onClose}
            sx={{
              mt: 2,
              width: "100%",
              height: "40px",
              fontFamily: "Montserrat !important",
              fontSize: "12px",
              borderColor: "#b5462a",
              color: "#b5462a",
              "&:hover": {
                backgroundColor: "#b5462a",
                color: "#fff",
                borderColor: "#b5462a !important",
              },
            }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

// PropTypes for the SaveModal component

SaveModal.propTypes = {
  isOpen: PropTypes.bool.isRequired, // Indicates if the modal is open
  onClose: PropTypes.func.isRequired, // Function to handle closing the modal
  onSaveAsPic: PropTypes.func.isRequired, // Function to handle saving as PNG
  onSaveAsCode: PropTypes.func.isRequired, // Function to handle saving as code
};

// Exporting the SaveModal component
export default SaveModal;
